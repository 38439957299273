:root {
  --primary-color: black;
  --secondary-color: grey;
  --third-color: darkgrey;
  --font-color: rgb(248, 248, 248);
  --second-font-color: grey;
  --third-font-color: white;
  --submenu-header-catigory-font-color: black;

  --menu-font-color: var(--second-font-color);
  --menu-font-color-clicked: var(--third-font-color);
  --menu-highlight-color: var(--third-color);
  --menu-header-background: var(--secondary-color);
  --footer-background: var(--third-color);
  --menu-section-font-color: var(--primary-color);
  --submenu-section-font-color: var(--primary-color);
  --menu-item-title-font-color: var(--primary-color);
  --menu-item-description-font-color: var(--primary-color);

  --main-font-size: 20px;
  --secondary-font-size: 16px;
  --column-count: 2;

  background-color: var(--primary-color);
}

.trevor {
  --primary-color: tan;
  --secondary-color: rgb(175, 127, 64);
  --third-color: rgb(175, 127, 64);
  --font-color: black;
  --second-font-color: rgb(3, 17, 9);
  --third-font-color: rgb(17, 2, 2);
  --submenu-header-catigory-font-color: black;

  --menu-font-color: var(--second-font-color);
  --menu-font-color-clicked: var(--third-font-color);
  --menu-highlight-color: var(--third-color);
  --menu-header-background: var(--secondary-color);
  --footer-background: var(--third-color);
  --menu-section-font-color: var(--font-color);
  --submenu-section-font-color: var(--font-color);
  --menu-item-title-font-color: var(--font-color);
  --menu-item-description-font-color: var(--font-color);

  --main-font-size: 20px;
  --secondary-font-size: 16px;
  --column-count: 2;

  background-color: var(--primary-color);
}

.default-font-color {
  color: var(--font-color)
}

.cannabis {
  --primary-color: rgb(250, 250, 250);
  --secondary-color: rgb(5, 59, 32);
  --third-color: rgb(5, 59, 32);
  --font-color: rgb(185, 247, 216);
  --second-font-color: rgb(6, 80, 34);
  --third-font-color: rgb(44, 151, 76);
  --submenu-header-catigory-font-color: black;

  --menu-font-color: var(---font-color);
  --menu-font-color-clicked: var(--third-font-color);
  --menu-highlight-color: var(--third-color);
  --menu-font-color-hilight: var(--second-font-color);
  --menu-header-background: var(--secondary-color);
  --footer-background: var(--third-color);
  --menu-section-font-color: var(--font-color);
  --submenu-section-font-color: var(--second-font-color);
  --menu-item-title-font-color: var(--font-color);
  --menu-item-description-font-color: var(--font-color);

  --main-font-size: 20px;
  --secondary-font-size: 16px;
  --column-count: 2;

  background-color: var(--primary-color);
}

.App {
  text-align: center;
  background-color: var(--primary-color);
  min-height: 100vh;
  color: var(--font-color);
}

.body {
  padding-bottom: 12%;
}

@media screen and (max-width: 768px) {
  .body {
    padding-bottom: 20%;
  }

}

.catigory-font-color{
  color: var(--second-font-color);
}

.search-input {
  margin-bottom:2%;
}

table {
  width:100%;

}

.tr-25 {
  width:26%;
}

.tr-20 {
  width:16%;
}

@media screen and (max-width: 768px) {

  tr, th, td {
    width:100% !important;
    display: block;
  }
  

  tr {
    display: block;
    border: 1px solid var(--third-font-color) !important;
  }

}

.mainLogo {
  height: 2em;
  float: left;
}

.adModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-Color: rgba(0,0,0,.5);
  z-Index: 1000;
}

.adModal img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* width: 90%; */
  height: 90%;
  margin-top: 2.5%;
  
}

@media screen and (max-width: 768px) {
  .adModal img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    /* height: 90%; */
    margin-top: 2.5%;
    
  }

}

.image-slider {
  height: 10%;
  width: 100%;
  position: fixed;
  right: 0;
  bottom: 0%;
  left: 0;
  z-index: 10;
}

.adBannerImages {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}


.home-page-img {
  width: 80%;
  margin-top: 3%;
}

.home-page-section {
  padding-top: 2%;
}

.footer {
  background-color: var(--footer-background);
  height: 5%;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
}

.submenu-header {
  background-color: var(--menu-header-background);
  width: 100%;
  display: flex;
  padding: 2%;
  overflow-x: auto;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.submenu-item {
  width: 100%;
  text-decoration: none;
}

.submenu-titles {
  color: var(--submenu-header-catigory-font-color);
  font-size: var(--catigory-font-size);
}

a {
  all: unset;
}


/* menu formatting */

.menu-item {
  text-align: left;
  justify-content: space-between;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

}


/* item page section */

.background-section {
  background: var(--third-color);
  margin: 3% 3% 0 3%;
  padding: 2%;
  border-radius: 25px;
}

.responsive-image {
  width: 80%;
}

.holder-background-sizing {
  
  margin-top: 5%;
  width: 100%;
}

.holder-background-color {
  background-color: var(--secondary-color);
}

.suggestion-images {
  display: flex;
}

.suggestion-images img {
  width: 90%;
  margin: 0 10px;
}

@media screen and (max-width: 768px) {
  .suggestion-images {
    flex-direction: column;

  }

  .suggestion-images img {
    width: 80%;
    margin: 5% 10% 5% 10%;
  }

}

.catigory-section {
  border-radius: 25px;
  background-color: var(--menu-highlight-color);
  margin: 3% 2% 0 2%;
  padding: 2% !important;
  flex: .5;

}

.col-count-amount {
  -webkit-column-count: var(--column-count);
  -moz-column-count: var(--column-count);
  column-count: var(--column-count);
}

@media screen and (max-width: 768px) {
  .catigory-section {
    flex-grow: 1;
  }

  .col-count-amount {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }

}


.catigory-section h4 {
  color: var(--menu-section-font-color);
  text-decoration: underline;
  text-align: center;
  margin: 0;
  width: 98%; 
}

.menu-catigory-title {
  color: var(--submenu-section-font-color);
  text-decoration: underline;
  margin: 3% 0 3% !important;

}

/* .menu-item-title {
  color: var(--menu-item-title-font-color);
  font-size: var(--main-font-size);
  width: 75%;
  margin: 0;
  padding: 0;
  float: left;
}

.menu-item-price {
  color: var(--menu-item-description-font-color);
  width: 25%;
  font-size: var(--main-font-size);
  float: right;
} 

.spacer {
  clear: both;
} */

.items {
  background-color: transparent !important;
  /* border-color: transparent !important; */
  --webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  margin-bottom: 3%;
}



/* below this point will be changes for UI update Need to review above */

/* catigory page specific wrapper */
.menu-catigory-wrapper {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top:2%;
  
  /* overflow: hidden; */
  display:flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.menu-catigory-placeholder {
  height: 100px;
  margin-left: 5%;
  margin-right: 5%;
  background-color: var(--third-color);
  color: var(--font-color);
}

.menu-catigory-placeholder-text{
  padding-top: 40px;
}

.menu-catigory-images {
  margin-top: 5px;
  /* height: auto; */
  max-width: 100%;
}

.menu-catigory-images:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}



.item-list-wrapper {
  width:90%;
  margin-left: 5%;
  margin-top: 5px;
}


.item-list-single-item {
  border: 1px solid black;
  width:100%;
  background-color: var(--third-color);
  padding-top: 1%;
  padding-bottom: 1%;
  margin-bottom: 1%;
}

.menu-item-title {
  color: var(--menu-item-title-font-color);
  font-size: var(--main-font-size);
  width: 75%;
  vertical-align: middle;
  float: left;
  margin:0;
  padding:0;
}

.menu-item-price {
  color: var(--menu-item-description-font-color);
  width: 25%;
  font-size: var(--main-font-size);
  float: right;
  margin:0;
  padding:0;
} 
.single-item-title {
  color: var(--menu-item-description-font-color);
  font-size: var(--main-font-size);

} 
.single-item-description {
  color: var(--menu-item-description-font-color);
  font-size: var(--secondary-font-size);

} 

.spacer {
  clear: both;
}