 /* App.module.css */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
}

.image-border {
    padding: 1%;
} 

.button-spacing {
    margin: 1%;
    padding: 3%;
    font-weight: bold;
    border-radius: 10px;
    text-wrap: nowrap;
}

h5 {
    margin-bottom: 2%;
}

li h5 {
    margin-bottom: 1%;
}

 /* Header.module.css */
.header {
  background-color: #f8f8f8;
  padding: 1rem;
  margin-bottom: 2%;
  
}

.two-columns {
 columns: 2;
}

.span-two{
    column-span: all;
}

.number-bullets {
    list-style-type: decimal;
}

.left {
    text-align: left;
}

.black-header-logo{
    width: 80px;
    margin-bottom: .5%;
}

.background-section {
    padding-left:  10%;
    padding-right: 10%;
    
}

.blue {
  color: #1A42E6;
  text-decoration: none;
}
.blue-link{
    color: #1A42E6;
    text-decoration: none;
}
.blue-link:hover {
    color: black;
}

.red {
  color: #B50404;
  text-decoration: none;
}
.red-link {
    color: #B50404;
    text-decoration: none;
  }
 
 .red-link:hover {
    color: black;
}

.green {
  color: #047e50;
  text-decoration: none;
}
.green-link {
    color: #047e50;
    text-decoration: none;
  }
 
 .green-link:hover {
    color: black;
}

.bold {
  font-weight: bold;
}

.chrome-background {
    background-image: url("img/MainWebsite/Chrome.jpg")
}

.chrome-background-1 {
    background-image: url("img/MainWebsite/Chrome_1.jpg")
}

.chrome-background-2 {
    background-image: url("img/MainWebsite/Chrome_2.jpg")
}

.nav {
  display: flex;
  justify-content: space-around;
}

.menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width:80%;
  justify-content: space-between;
}

.home-image {
    width: 100%;
    max-width: 700px;
}

.vizo-logo-image {
   width:80%;
   padding-bottom: 8%;
}

.font-courgette {
    font-family: "Courgette", cursive;
    font-weight: 400;
    font-style: normal;
}

.page-image {
    width: 70%;
    padding-bottom: 7%;
}

.menu li {
  margin-right: 1rem;
}

.hamburger {
  display: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
    color: black;
  }

  .menu {
    display: none;
    flex-direction: column;
    top: 100%;
    left: 0;
    width: 100%;
    
  }

  .open {
    display: flex;
    flex-direction: column;
    
  }

  .header-option {
    margin: 0.5rem 0;
  }
}

 /* Footer.module.css */
.footer {
  background-color: #f8f8f8;
  padding: .5rem;
  padding-bottom: 2%;
  text-align: center;
  margin-top: auto;
  color: black;
}

main {
    padding-bottom:5%;
}

.display-none {
    display: none;
}

 /* MainContent.module.css */
.mainContent {
  display: flex;
  justify-content: space-around;
  padding: 2rem;
}

.column {
  flex: 1;
  margin: 0 .5rem;
  text-align: center;
}

.column img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .mainContent {
    flex-direction: column;
  }

  .column {
    margin: 1rem 0;
  }
}