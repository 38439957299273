/* Modal.css */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
  }
  
  .modal-main {
    position: fixed;
    background: var(--primary-color);
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--second-font-color);
    width: 80%;
    max-width: 300px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  